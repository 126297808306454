import Investors from '@components/book-trial-class-jp/investors'
import Footer from '@components/common/footer'
import Navbar from '@components/common/navbar'
import Articles from '@components/home/articles'
import Faq from '@components/home/faq'
import MoneyBackGuarantee from '@components/home/guarantee'
import LearningDashboard from '@components/home/learning-dashboard'
import Teachers, { StatisticsProps } from '@components/home/teachers'
import TrustpilotReview from '@components/locale-pages/home/trustpilot-review'
import VideoTestimonial from '@components/locale-pages/home/video-testimonial'
import Partners from '@components/locale-pages/home/us/partners'
import FirstFold from '@components/math-class/first-fold'
import MathBenifits from '@components/math-class/math-benifits'
import MathEngagementFold from '@components/math-class/math-engagement-fold'
import MathHelp from '@components/math-class/math-help'
import Overview from '@components/math-class/overview'
import TabbedCurriculum from '@components/math-class/tabbed-curriculum'
import WhyCodingal from '@components/math-class/why-codingal'
import { FaqContentType } from '@lib/data/faq'
import { VIDEO_TESTIMONIAL } from '@lib/data/summer-coding-camp'
import { formatSeoConfig } from '@lib/seo'
import { defineMessage } from '@lingui/macro'
import { CourseJsonLd, FAQPageJsonLd, NextSeo } from 'next-seo'
import CodingCertificate from '@components/home/coding-certificate'
import CodingClassCatalog from '@components/home/coding-class-catalog'

const STATISTICS: StatisticsProps[] = [
  {
    title: '🎓 300+',
    description: defineMessage({
      message: `Teachers with Bachelor's and Master's degrees in mathematics.`,
    }),
  },
  {
    title: '👨‍💻 7+',
    description: defineMessage({
      message: 'Years teaching experience',
    }),
  },
  {
    title: '👩 91%',
    description: defineMessage({
      message: 'Female teachers',
    }),
  },
]
const urlQuery = '?course=math-trial-class'

export const FAQ_CONTENTS: FaqContentType[] = [
  {
    questionName: defineMessage({
      message: `Are Codingal's teachers qualified?`,
    }),
    acceptedAnswerText: (
      <>
        Yes, all Codingal teachers are highly qualified and experienced. They
        hold at least a Bachelor’s degree in mathematics or a related field, and
        many have Master’s degrees or higher. They are also trained in the
        latest educational techniques.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Yes, all Codingal teachers are highly qualified and experienced. They hold at least a Bachelor’s degree in mathematics or a related field, and many have Master’s degrees or higher. They are also trained in the latest educational techniques.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What technological requirements are needed for online classes?`,
    }),
    acceptedAnswerText: (
      <>
        Students will need a computer or tablet with internet access, a webcam,
        and a microphone. Our platform is accessible through most web browsers
        without the need for special software.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `Students will need a computer or tablet with internet access, a webcam, and a microphone. Our platform is accessible through most web browsers without the need for special software.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `What is the class size in Codingal's online math courses?`,
    }),
    acceptedAnswerText: (
      <>
        To ensure personalized attention and an optimal learning environment,
        our classes are limited to one teacher per student or small groups,
        depending on the course structure.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `To ensure personalized attention and an optimal learning environment, our classes are limited to one teacher per student or small groups, depending on the course structure.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How do Codingal’s classes work?`,
    }),
    acceptedAnswerText: (
      <>
        At Codingal, our online math classes are delivered through our
        proprietary platform. Each session is live and conducted one-on-one with
        a dedicated math expert. Students typically have two or more live
        sessions per week with their instructor. To participate in a class,
        students require a desktop or laptop computer equipped with headphones,
        a microphone, and a broadband internet connection.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `At Codingal, our online math classes are delivered through our proprietary platform. Each session is live and conducted one-on-one with a dedicated math expert. Students typically have two or more live sessions per week with their instructor. To participate in a class, students require a desktop or laptop computer equipped with headphones, a microphone, and a broadband internet connection.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How does Codingal help a child who is already good at math?`,
    }),
    acceptedAnswerText: (
      <>
        At Codingal, we recognize that scoring high in school exams doesn’t
        necessarily reflect a child’s true understanding of math. It’s common
        for students to achieve scores of 95% to 100% yet lack a solid grasp of
        fundamental math concepts. This often leads to difficulties in higher
        grades as the subject matter becomes more complex. At Codingal, we
        provide personalized attention to identify and strengthen areas where
        each student needs improvement. For those who already excel in the
        basics, our program advances into more complex areas, including
        preparation for math Olympiads and increasing challenge levels to keep
        engagement high. Moreover, to prevent boredom and maintain interest for
        students who might not feel sufficiently challenged by their school
        curriculum, Codingal offers a variety of stimulating activities like
        tab-based exercises and interactive puzzles.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `At Codingal, we recognize that scoring high in school exams doesn’t necessarily reflect a child’s true understanding of math. It’s common for students to achieve scores of 95% to 100% yet lack a solid grasp of fundamental math concepts. This often leads to difficulties in higher grades as the subject matter becomes more complex. At Codingal, we provide personalized attention to identify and strengthen areas where each student needs improvement. For those who already excel in the basics, our program advances into more complex areas, including preparation for math Olympiads and increasing challenge levels to keep engagement high. Moreover, to prevent boredom and maintain interest for students who might not feel sufficiently challenged by their school curriculum, Codingal offers a variety of stimulating activities like tab-based exercises and interactive puzzles.`,
    }),
  },
  {
    questionName: defineMessage({
      message: `How is Codingal better than home tuitions?`,
    }),
    acceptedAnswerText: (
      <>
        At Codingal, we believe that effective learning extends beyond homework
        completion and exam preparation. While home tuitions often concentrate
        merely on these areas, repeating what students learn at school, we aim
        to deepen and expand their mathematical understanding. Our curriculum is
        meticulously structured and delivered through online worksheets and
        interactive simulations, ensuring students not only understand but excel
        in math fundamentals. Our instructors are carefully selected and undergo
        comprehensive training to maintain the highest teaching standards.
        Unlike traditional home tuitions, which may have an unrestricted number
        of students, a Codingal class maintains a small group setting with no
        more than 7 to 8 students from various grades. This approach minimizes
        competition, fostering a more supportive learning environment, and
        allows our teachers to provide personalized attention to each student,
        ensuring individual growth and understanding.
      </>
    ),
    acceptedAnswerTextString: defineMessage({
      message: `At Codingal, we believe that effective learning extends beyond homework completion and exam preparation. While home tuitions often concentrate merely on these areas, repeating what students learn at school, we aim to deepen and expand their mathematical understanding. Our curriculum is meticulously structured and delivered through online worksheets and interactive simulations, ensuring students not only understand but excel in math fundamentals. Our instructors are carefully selected and undergo comprehensive training to maintain the highest teaching standards. Unlike traditional home tuitions, which may have an unrestricted number of students, a Codingal class maintains a small group setting with no more than 7 to 8 students from various grades. This approach minimizes competition, fostering a more supportive learning environment, and allows our teachers to provide personalized attention to each student, ensuring individual growth and understanding.`,
    }),
  },
]

export const MathCourse = formatSeoConfig({
  title: 'Best Online Math Classes for Kids and Teens',
  description:
    'Codingal offers fun online math classes & tutoring for kids & teens to build a strong foundation in mathematics. Sign up today to get a free class!',
  url: 'https://www.codingal.com/courses/math',
  metaImage: 'https://fastly.codingal.com/images/courses/math/og-image.png',
})

const MathClass = () => {
  return (
    <>
      <NextSeo {...MathCourse} />
      <FAQPageJsonLd
        mainEntity={FAQ_CONTENTS.map(
          ({ acceptedAnswerText, questionName }) => ({
            acceptedAnswerText: acceptedAnswerText,
            questionName: questionName,
          })
        )}
      />
      <CourseJsonLd
        courseName="AP Computer Science A"
        description="Prepare for the AP Computer Science A exam with live 1:1 online classes. Learn fundamentals of java programming, data structures, algorithms and much more."
        provider={{
          '@type': 'Organization',
          name: 'Codingal',
        }}
        offers={{
          '@type': 'Offer',
          category: 'Paid',
        }}
        hasCourseInstance={{
          '@type': 'CourseInstance',
          courseMode: 'Online',
          courseWorkload: 'PT48H',
        }}
      />
      <Navbar tryFreeClassLink={`/register/${urlQuery}`} />
      <FirstFold />
      <Partners />
      <Overview />
      <TabbedCurriculum />
      <WhyCodingal />
      <VideoTestimonial
        title="Hear from our students how our math courses changed their lives"
        video_testimonial={VIDEO_TESTIMONIAL}
      />
      <TrustpilotReview urlQuery={urlQuery} />
      <MathEngagementFold />
      <MathHelp />
      <Teachers
        isShowBecomeTeacher={false}
        title="Learn math from the finest educators in the field. Our hand-picked instructors guarantee the best tutoring experience"
        urlQuery={urlQuery}
        statistics={STATISTICS}
        imagePath="/images/courses/math/teachers"
      />
      <MathBenifits />
      <CodingCertificate
        title={
          <>
            Unlock your skills and earn{' '}
            <span className="yellow-underline">
              Math completion certifications
            </span>
          </>
        }
        description={
          "In this specialized math course, learners in grades 1 to 12 earn certificates that celebrate their achievements while unlocking special badges and leaderboard rankings, making math for kids a fun and rewarding experience. Students can earn the Rising Math Star, Math Champion, Math Prodigy, and Math Grandmaster Certificates. These certifications are easy to download and share, fully aligned with U.S. Common Core standards, and accredited by STEM.org. They can be added to your child's learning portfolio for sharing with the world and on social media."
        }
      />
      <MoneyBackGuarantee urlQuery={urlQuery} />
      <LearningDashboard urlQuery={urlQuery} />
      <Faq
        title="Got questions? Find answers in our FAQ section"
        faqContents={FAQ_CONTENTS}
      />
      <Articles />
      <Investors ctaSize="xl" urlQuery={urlQuery} />
      <CodingClassCatalog
        title={
          <>
            Our <span className="yellow-underline">Math course</span> is
            appropriate for High School, Middle School, and Elementary School
            students
          </>
        }
        description={
          <>
            <p>
              Our Math course is suitable for elementary, middle, and high
              school students in grades 1 to 12. This course is tailored for
              each grade, covering topics from beginner to advanced math
              concepts. In grades 1–3, students are introduced to the Number
              System, Number Operations, Fractions, Time & Measurement,
              Geometry, and Data Handling. In grades 4–5, students progress to
              intermediate concepts such as Factors & Multiples, Fractions &
              Decimals, Time, Money & Measurement, Geometry & Patterns, and Data
              Handling. In grades 6–8, students learn the Number System, Number
              Operations, Fractions, Time & Measurement, Geometry, and Data
              Handling. In grades 9–12, teens learn advanced concepts in topics
              like Numbers, Algebra, Geometry, Mensuration, and Data.
            </p>
            <p>
              Codingal supports kids ages 6 to 18 in grades K to 12 by providing
              engaging and comprehensive math & coding education that fosters
              creativity and critical thinking skills.
            </p>
          </>
        }
      />
      <Footer />
    </>
  )
}

// TODO: Remove once getInitialProps is removed from _app.tsx
export const getStaticProps = async () => {
  return {
    props: {},
  }
}

export default MathClass
